import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const ADD_EXPENSE_REPORT = gql`
  mutation AddExpenseReport(
    $expenseReportFileUuid: ID!, 
    $invoiceDate: Date!, 
    $expenseReportCategoryUuid: String!, 
    $amountExcludingVat: Float!, 
    $totalAmount: Float!, 
    $countryCode: String!, 
    $comment: String!, 
    $currency: String!,
    $invoiceIssuer: String!, 
    $vatList: [NewExpenseReportVatRequestDto!]
  ) {
    addExpenseReport(
      newExpenseReport: {
        invoiceDate: $invoiceDate
        amountExcludingVat: $amountExcludingVat
        totalAmount: $totalAmount
        invoiceIssuer: $invoiceIssuer
        expenseReportFileUuid: $expenseReportFileUuid
        expenseReportCategoryUuid: $expenseReportCategoryUuid
        countryCode: $countryCode
        comment: $comment
        vatList: $vatList
        currency: $currency
      }
    ) {
      uuid
    }
  }
`;

export const useAddExpenseReport = () => {
    const { user } = useAuth();
    const [addExpenseReportMutation, { loading, error, data }] = useMutation(ADD_EXPENSE_REPORT, {
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
    });

    const addExpenseReport = async ({
        expenseReportFileUuid,
        invoiceDate,
        expenseReportCategoryUuid,
        amountExcludingVat,
        totalAmount,
        countryCode,
        comment,
        invoiceIssuer,
        vatList,
        currency
    }) => {
        const response = await addExpenseReportMutation({
            variables: {
                expenseReportFileUuid,
                invoiceDate,
                expenseReportCategoryUuid,
                amountExcludingVat,
                totalAmount,
                countryCode,
                comment,
                invoiceIssuer,
                vatList,
                currency

            },
        });
        return response.data.addExpenseReport;
    };

    return { addExpenseReport, loading, error, data };
};
