import * as React from "react";

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import { Label } from "../../atoms/texts/label";
import { Button } from "../../molecules/buttons/button";
import cn from 'classnames';
import { useNavigate } from "react-router-dom";
import { correctUrl } from "@/utils/correctUrl";
// import useNotifications from "@/hooks/global-hooks/useNotification";
import { useNotifications } from "@/hooks/global-hooks/NotificationContext";

function formatDate(dateString) {
    const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('fr-FR', optionsDate);
    const formattedTime = date.toLocaleTimeString('fr-FR', optionsTime);
    return `${formattedDate} - ${formattedTime}`;
}

export default function Notification({ notification, showDate = true }) {
    // console.log("notification", notification)
    const { refetch, readNotification, deleteNotification  } = useNotifications();
    const variant = {
        ERROR: {
            borderColorClass: "border-error",
            bgColorUnread: "bg-error20",
            Icon: WarningRoundedIcon,
            iconColor: "error"
        },
        INFO: {
            borderColorClass: "border-information",
            bgColorUnread: "bg-information20",
            Icon: DoneAllRoundedIcon,
            iconColor: "info" // Assurez-vous que cette couleur existe dans votre système de design
        },
        SUCCESS: {
            borderColorClass: "border-success",
            bgColorUnread: "bg-success20",
            Icon: CheckCircleRoundedIcon,
            iconColor: "success"
        },
        WARNING: {
            borderColorClass: "border-warning",
            bgColorUnread: "bg-warning20",
            Icon: CheckCircleRoundedIcon,
            iconColor: "warning"
        },
    }[notification.type] || {};

    const BorderColorClass = variant.borderColorClass;
    const BgColorUnread = variant.bgColorUnread;
    const Icon = variant.Icon;
    const iconColor = variant.iconColor;
    const [isUnread, setIsUnread] = React.useState(notification.readStatus === 'UNREAD');
    const [isVisible, setIsVisible] = React.useState(true)
    const navigate = useNavigate();

    const handleNavigate = () => {
        var correctedUrl = correctUrl(notification.deepLink);
        console.log("correctedUrl == ", correctedUrl);
        navigate(notification.deepLink);
        // const url_= window.location + "/"+notification.deepLink
        // navigate(url_);
    }

    const handleMouseEnter = () => {
        setIsUnread(false);
        if (isUnread) {
            // console.log("handleMouseEnter", notification.uuid);
            readNotification(notification.uuid);
            refetch();
        }
    }
    const handleDelete = (e) => {
        e.stopPropagation();  // Empêche le comportement de clic du parent
        deleteNotification(notification.uuid);
        refetch();
        setIsVisible(false);
    }
    return (
        isVisible ? <div
            className={cn("flex w-full justify-center items-center gap-firstDegree border-inside rounded-sm px-firstDegree py-3", BorderColorClass, {
                'border-2': isUnread,
                'border': !isUnread,
                'bg-lighter' : !isUnread,
                [BgColorUnread]: isUnread, 
                
            })}
            onMouseUp={isUnread && handleMouseEnter }
            onDoubleClick={handleNavigate}
        >
            {Icon && <Icon color={iconColor} />}
            <div className="flex flex-col w-full gap-0 leading-none ">
                <Label wFull size="" fontWeight={isUnread ? "bold" : "regular"} className="hover:underline hover:cursor-pointer" onClick={handleNavigate}>{notification.message}</Label>
                {isUnread ? (
                    <Label size="caption" fontWeight="bold" color="grey" >{formatDate(notification.createdAt)}</Label>
                ) : (
                    showDate && <Label size="caption" fontWeight="semibold" color="grey">{formatDate(notification.createdAt)}</Label>
                )}
            
            </div>
            {/* <div className="flex w-fit px-2 p-2 font-bold text-primary bg-medium rounded-full hidden hover:cursor-pointer group-hover:flex" ><Label message="global.actions.moreNotif" ></Label></div> */}
            {/* {notification.deepLink && <Button style="pill" sizePadding="pill" text="global.actions.moreNotif" onClick={handleNavigate}>More</Button>} */}
            <Button onClick={(e) => handleDelete(e)} showText={false} showLeftIcon leftIcon={<DeleteSweepRoundedIcon fontSize="small"/>} style="ghost" sizePadding="small" className='z-100'></Button>
        </div> : <></>
    );
}
