export function correctUrl(url) {
    // Obtenir la racine dynamiquement
    const siteRoot = `${window.location.protocol}//${window.location.host}`;
    
    // Obtenir le chemin actuel de l'URL
    const currentPath = window.location.pathname;
    
    // Combiner la racine du site et l'URL fournie
    const fullUrl = new URL(url, `${siteRoot}${currentPath}`).pathname;

    // Remplacer les répétitions dans l'URL combinée
    const correctedUrl = fullUrl.replace(/\/(\w+)(\/\1)+/g, '/$1');
    
    // Assurer que l'URL corrigée est relative
    console.log("correctedurl returned = ", correctedUrl.replace(siteRoot, ''));
    return correctedUrl.replace(siteRoot, '');
}
