import React, { useState, useEffect, useRef, useMemo } from 'react';
// import { DnDFileInput } from '@shadcn/ui';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/organisms/forms/select";
import DragAndDropZone from '../../../../organisms/forms/DragAndDropZone'
import { Label } from '@/components/atoms/texts/label';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
// import ExpenseNotification from './ExpenseNotification';
import useFormNewExpense from './useFormNewExpense';
import FormIsSend from '@/components/organisms/feedback/formIsSend';
import useExpenseReportCategories from '@/services/consultant-services/query/useExpenseReportCategories';
import getAppLanguage from '@/utils/getAppLanguage';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import useAllCurrencies from '@/services/global-services/query/useAllCurrencies';
import { useActiveVats } from '@/services/admin-services/query/useAllVats';
import CountrySelect from '@/components/molecules/inputs/SelectCountry';
import { countries } from 'countries-list'
import { useTranslation } from 'react-i18next';
import getCurrencySymbolFromCode from '@/utils/getCurrencySymbolFromCode';
import { Input } from '@/components/ui/input';
import PrintWarningMessage from '@/components/organisms/feedback/PrintWarningMessagee';
import { useApproveExpenseReport } from '@/services/admin-services/mutations/useApproveExpenseReport';
import { useCallback } from 'react';
import { getCountryList, transformedCountries } from '@/utils/getCountriesLanguagesAndCode';
import { Switch } from '@/components/ui/switch';
import { IMPACT_OVERFLOW } from '@/utils/globalConst';
import { Textarea } from '@/components/ui/textarea';
import ReadingStatus from './readingStatus';

export default function FormNewExpense({ onSubmit, submitRef, data, isForApprovalUseCase, forEdition, submissionError, submissionSuccess, refetchFromScratch, setSubmissionSuccess, setSubmissionError, setEditFile }) {

    // const categories = ["travelExpenses", "mealExpenses", "accommodation", "officeSupplies", "softwareSubscriptions", "professionalServices", "trainingFees", "vehicleExpenses", "communicationFees", "entertainment", "publicTransport", "fuelCosts", "conferenceFees", "marketingAndAdvertising", "clientGifts"]
    const { loading: loadingCategories, error: errorCategories, categories } = useExpenseReportCategories(getAppLanguage());
    const { loading: loadingCurrencies, error: errorCurrencies, currencies } = useAllCurrencies();
    const { loading: loadingVat, error: errorVat, data: vatRates } = useActiveVats();
    const { loading: loadingApprove, error: errorApprove } = useApproveExpenseReport();

    // const vatRates = ["5%", "10%", "12%", "14%", "20%"];
    const [dateSend, setDateSend] = useState();
    const [amountSend, setAmountSend] = useState();
    const [currencySend, setCurrencySend] = useState();
    const [showInputFile, setShowInputFile] = useState(forEdition ? false : true);
    const [t] = useTranslation("global");

    const scrollContainerRef = useRef(null);
    // const countriesWithPriority = useCallback(getCountryList(),[]) ;
    const [allCurrencies, setAllCurrencies] = useState();
    const [allCategories, setAllCategories] = useState();
    const [countriesWithPriority, setcountriesWithPriority] = useState();

    const parentRef = useRef(null);
    const [isParentWidthLessThan800, setIsParentWidthLessThan800] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                setIsParentWidthLessThan800(parentRef.current.offsetWidth < 800);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        currencies && setAllCurrencies(currencies);
    }, [currencies]);

    useEffect(() => {
        categories && setAllCategories(categories);
    }, [categories]);

    useEffect(() => {
        let countries = getCountryList();
        setcountriesWithPriority(countries);
    }, []);





    const changeImpactsOverflow = () => {
        // true or false
        if (impactsOverflow === IMPACT_OVERFLOW[true].enum) {
            setImpactsOverflow(IMPACT_OVERFLOW[false].enum);
        } else {
            setImpactsOverflow(IMPACT_OVERFLOW[true].enum);
        }
    }
    // const declineExpense = () => {
    //     handleSubmitForm(e, "-1");
    // }

    const {
        file,
        date,
        currency,
        selectValue,
        amount,
        vatValues,
        totalVat,
        totalAmount,
        vendor,
        country,
        comment,
        impactsOverflow,
        vatNotAvailable,
        handleVatChange,
        resetForm,
        declineExpense,
        approveExpense,
        toNumber,
        fileError,
        dateError,
        selectValueError,
        amountError,
        totalAmountError,
        commentError,
        vendorError,
        countryError,
        totalVatError,
        setFile,
        setDate,
        setCurrency,
        setSelectValue,
        setAmount,
        setTotalAmount,
        setVendor,
        setCountry,
        setComment,
        setImpactsOverflow,
        validateForm,
        adminComment,
        adminCommentError,
        setAdminComment

    } = useFormNewExpense(data, refetchFromScratch, setSubmissionError, setSubmissionSuccess, vatRates);


    useEffect(() => {
        if (submitRef) {
            submitRef.approve = {
                approveExpense: (uuid) => approveExpense(uuid)
            };

            submitRef.decline = {
                declineExpense: (uuid) => declineExpense(uuid)
            };

            submitRef.reset = {
                resetForm: resetForm
            };
        }
    }, [approveExpense, declineExpense, resetForm, submitRef]);


    const scrollToTop = () => {
        // importer le hook useRef : import {useRef} from "react";
        // Déclarer la ref :     const scrollContainerRef = useRef(null);
        // ajouter au contenant : className="h-full overflow-auto" ref={scrollContainerRef} 
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0; // Définit la position de défilement à 0 pour remonter en haut
        }
    };
    const handleSubmitForm = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        // const valid = showInputFile ? validateForm("allWithoutFile") : validateForm("all");
        const valid = showInputFile ? validateForm("all") : validateForm("allWithoutFile");

        console.log("valid = ", valid, fileError, dateError, selectValueError, amountError, totalAmountError, commentError, vendorError, countryError, totalVatError)
        const vatList = Object.entries(vatValues).map(([vatUuid, vatData]) => ({
            vatUuid,
            vatAmount: toNumber(parseFloat(vatData?.vatAmount) * vatData?.vatRate / 100)
        }));
        // const id = data ? data.id : undefined;
        if (valid) {
            const formData = {
                file,
                invoiceDate: date,
                amountExcludingVat: toNumber(amount),
                totalAmount: toNumber(totalAmount),
                invoiceIssuer: vendor,
                expenseReportCategoryUuid: selectValue,
                countryCode: country?.toLowerCase(),
                comment: comment,
                vatList: vatList,
                currency: currency //TODO : tva : requeter la liste des tva, gérer dynamiquement l'affichage et renvoyer l'uuid de la tva (vatUuid) avec le montant (vatAmountvatAmount)

            };
            setDateSend(date);
            setAmountSend(totalAmount);
            setCurrencySend(currency);

            const { submissionSuccess, submissionError } = onSubmit(formData); // Appelle la fonction de soumission passée en props
            // console.log("submissionSuccess", submissionSuccess, "submissionError", submissionError)
            // resetForm();

        }
        scrollToTop();
    }
    useEffect(() => {
        submissionSuccess && resetForm();
        submissionSuccess && setSubmissionSuccess(false);
        console.log("FormNewExpense submissionSuccess change", submissionSuccess)
    }, [submissionSuccess]);

    return (
        <div className="h-full overflow-auto p-2 " ref={scrollContainerRef}>
            {/* {submissionError && <PrintErrorMessage error={submissionError} />} */}
            {submissionSuccess && <FormIsSend message={
                <span>
                    <Label message="success.sendForm.expense" />
                    <Label>{dateSend}</Label>
                    <Label message="success.sendForm.forAmount" />
                    <Label>{amountSend} {currencySend}</Label>
                    <Label message="success.sendForm.continue" />
                </span>
            } />}

            <form onSubmit={handleSubmitForm} className='flex flex-col gap-thirdDegree p-0 pb-4' ref={parentRef}>
                <div className={`flex flex-row ${isParentWidthLessThan800 && 'flex-col'}`}>
                    <div className="flex flex-col gap-thirdDegree p-4 w-full">

                        {!isForApprovalUseCase && <div className='flex flex-col gap-secondDegree'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.supportingDocuments" />
                            {/* Switch pour éditer le fichier */}
                            {forEdition && <div className="flex items-center gap-2 w-full">
                                <Label fontWeight="" className="" message="global.actions.editFile" />
                                <Switch
                                    checked={showInputFile}
                                    onCheckedChange={(value) => { setShowInputFile(value); setEditFile(value) }}
                                    className=""
                                />
                            </div>}
                            {showInputFile && <DragAndDropZone file={file} setFile={setFile} fileError={fileError} accept="application/pdf, image/png, image/jpeg" />}                        </div>}

                        {/* Switch impact Overflow */}
                        {(isForApprovalUseCase && country == "LU") && <div className='flex flex-col p-firstDegree gap-secondDegree w-full rounded-sm bg-warning20 border border-warning'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.includeOverflow?" />

                            <div className=' items-center flex flex gap-secondDegree'>
                                <Label fontWeight={impactsOverflow == IMPACT_OVERFLOW[false].enum && `bold`} color={impactsOverflow == IMPACT_OVERFLOW[false].enum && `primary`} message="consultants.expense.impactsOverflow.false" />
                                <Switch checked={impactsOverflow == IMPACT_OVERFLOW[true].enum} onCheckedChange={changeImpactsOverflow} />
                                <Label fontWeight={impactsOverflow == IMPACT_OVERFLOW[true].enum && `bold`} color={impactsOverflow == IMPACT_OVERFLOW[true].enum && `primary`} message="consultants.expense.impactsOverflow.true" />
                            </div>
                        </div>}


                        {/* adminComment */}
                        {(isForApprovalUseCase) && <div className='flex flex-col p-firstDegree gap-secondDegree w-full rounded-sm bg-warning20 border border-warning'>
                            <div className='flex gap-firstDegree items-center'>
                                <ReadingStatus type={data?.status} />
                                <Label fontWeight="bold" className="col-span-1" message="consultants.expense.adminComment" />
                                <Label className="italic text-bodySmall" message="global.information.optional" />
                            </div>
                            <Textarea
                                disabled={!isForApprovalUseCase}
                                value={adminComment}
                                onChange={(e) => { setAdminComment(e.target.value) }}
                                className={`col-span-3 p-2 border rounded-md text-body ${adminCommentError === true && `border-error`} ${adminCommentError === false && `border-success`} ${adminCommentError == null && `border-greyStrong`} `}
                            // onBlur={adminCommentError ? validateForm("adminCommentErrorTest") : undefined}
                            />
                        </div>}

                        {/* Date */}
                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.date" />
                            <Input
                                type="date"
                                value={date}
                                disabled={isForApprovalUseCase}
                                onChange={(e) => { setDate(e.target.value) }}
                                className={`col-span-3 p-2 border rounded-md text-body ${dateError === true && `border-error`} ${dateError === false && `border-success`} ${dateError == null && `border-greyStrong`} `}
                            // onBlur={dateError ? validateForm("dateErrorTest") : undefined}
                            />
                        </div>

                        {/* Categories */}
                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.category" />
                            {/* {console.log("category selectValue", selectValue)} */}
                            {(errorCategories) && <PrintErrorMessage error={errorCategories} className={'col-span-3'} />}
                            {/* {loadingCategories && <span className='loader' />} */}
                            {/* && (loadingCategories || !categories) ? <span className='loader' /> : */}
                            {!errorCategories &&
                                <Select className="" defaultValue={selectValue} value={selectValue} onValueChange={(e) => { e && setSelectValue(e) }} >
                                    <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body  ${selectValueError === true && `border-error`} ${selectValueError === false && `border-success`} ${selectValueError === null && `border-greyStrong`} `} disabled={isForApprovalUseCase}                            >
                                        <SelectValue placeholder="Select a category" />
                                    </SelectTrigger>
                                    <SelectContent>

                                        {allCategories?.map((category, index) => (
                                            <SelectItem key={index} value={category?.uuid}>
                                                {/* <Label message={`consultants.expense.categories.${category}`} /> */}
                                                <Label>{category.label}</Label>
                                            </SelectItem>
                                        ))}
                                    </SelectContent>



                                </Select>}
                        </div>

                        {/* (loadingCurrencies || !currencies) ? <span className='loader' /> : */}

                        {/* Currencies */}
                        <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                            <Label fontWeight="bold" className="col-span-1" message="consultants.expense.currency" />
                            {errorCurrencies && <PrintErrorMessage error={errorCurrencies} className={'col-span-3'} />}
                            {!errorCurrencies &&
                                <Select className="" value={currency} onValueChange={(e) => { setCurrency(e) }} disabled={isForApprovalUseCase}                      >

                                    <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body `}>
                                        <SelectValue placeholder="Currency" />
                                    </SelectTrigger>
                                    <SelectContent >
                                        {loadingCurrencies ? <span className='loader' /> : (allCurrencies?.map((cur, index) => (
                                            <SelectItem key={index} value={cur.code}>
                                                <Label >{cur.code + " " + cur.symbol}</Label>
                                            </SelectItem>
                                        )))}
                                    </SelectContent>
                                </Select>}
                        </div>

                        {/* Vendor */}
                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.vendor" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="text"
                                    onWheel={(e) => e.target.blur()}
                                    value={vendor}
                                    onChange={(e) => { setVendor(e.target.value) }}
                                    placeholder={`---`}
                                    disabled={isForApprovalUseCase}
                                    className={`p-2 border rounded-md grow text-body  ${vendorError === true && `border-error`} ${vendorError === false && `border-success`} ${vendorError == null && `border-greyStrong`} `}
                                // onBlur={amountError ? validateForm("amountErrorTest") : undefined}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-thirdDegree p-4 w-full">
                        {/* Comment */}
                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.comment" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="text"
                                    onWheel={(e) => e.target.blur()}
                                    value={comment}
                                    onChange={(e) => { setComment(e.target.value) }}
                                    placeholder={`---`}
                                    disabled={isForApprovalUseCase}
                                    className={`p-2 border rounded-md grow text-body  ${commentError === true && `border-error`} ${commentError === false && `border-success`} ${commentError == null && `border-greyStrong`} `}
                                // onBlur={amountError ? validateForm("amountErrorTest") : undefined}
                                />

                            </div>
                        </div>
                        {!country && <PrintWarningMessage warning="consultants.expense.selectCountry" />}

                        {/* Country */}
                        <div className='grid grid-cols-4 gap-secondDegree w-full items-center'>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.country" />
                            {/* <div className='col-span-3 flex items-center gap-firstDegree'> */}
                            {/* <Input
                            type="text"
                            onWheel={(e) => e.target.blur()}
                            value={country}
                            onChange={(e) => { setCountry(e.target.value) }}
                            placeholder={`---`}
                            className={`p-2 border rounded-md grow text-body  ${amountError === true && `border-error`} ${amountError === false && `border-success`} ${amountError == null && `border-greyStrong`} `}
                        // onBlur={amountError ? validateForm("amountErrorTest") : undefined}
                        /> */}

                            <Select defaultValue={country} value={country} onValueChange={(value) => { value && setCountry(value) }} disabled={isForApprovalUseCase}
                            >
                                <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body  ${countryError === true && `border-error`} ${countryError === false && `border-success`} ${countryError === null && `border-greyStrong`}`}>
                                    <SelectValue
                                        placeholder={<Label message="consultants.informations.country" />}
                                    />

                                </SelectTrigger>
                                <SelectContent className="h-48">
                                    {!countriesWithPriority ? <SelectItem><span className='loader' /> </SelectItem> : Object.keys(countriesWithPriority).map((countryCode) => (
                                        <SelectItem key={countryCode} value={countryCode} className="text-body ">
                                            <Label>{countriesWithPriority[countryCode].name}</Label>
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            {/* {!countriesWithPriority && <PrintErrorMessage error label="error.fromServer.unknownError" className={'col-span-3'} />} */}

                            {/* </div> */}
                        </div>

                        {/* Amount total if coutry is'nt lu (luxembourg) */}
                        <div className={`grid grid-cols-4 gap-secondDegree w-full items-center ${(!country || country == "LU") && 'hidden'}`}>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.amountInclTaxs" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="number"
                                    min="0"
                                    step="0.01"  // Permet de saisir des nombres décimaux
                                    onWheel={(e) => e.target.blur()}
                                    value={totalAmount}
                                    onChange={(e) => { setTotalAmount(e.target.value) }}
                                    placeholder={`0,00 ` + currency}
                                    className={` text-right ${totalAmountError === true && `border-error`} ${totalAmountError === false && `border-success`} ${totalAmountError === null && `border-greyStrong`}  `}
                                    disabled={isForApprovalUseCase}
                                // onBlur={amountError ? validateForm("amountErrorTest") : undefined}
                                />
                                {getCurrencySymbolFromCode(currency)}
                            </div>

                        </div>

                        {/* AmountExcl Tax */}
                        <div className={`grid grid-cols-4 gap-secondDegree w-full items-center ${country !== "LU" && 'hidden'}`}>
                            <Label fontWeight="bold" className="col-span-1  truncate" message="consultants.expense.amountExclTaxs" />
                            <div className='col-span-3 flex items-center gap-firstDegree'>
                                <Input
                                    type="number"
                                    min="0"
                                    step="0.01"  // Permet de saisir des nombres décimaux
                                    onWheel={(e) => e.target.blur()}
                                    value={amount}
                                    onChange={(e) => { setAmount(e.target.value) }}
                                    placeholder={`0,00 ` + currency}
                                    className={` text-right ${amountError === true && `border-error`} ${amountError === false && `border-success`} ${amountError === null && `border-greyStrong`}  `}
                                    disabled={isForApprovalUseCase}
                                // onBlur={amountError ? validateForm("amountErrorTest") : undefined}
                                />
                                {getCurrencySymbolFromCode(currency)}
                            </div>

                        </div>

                        <div className="flex flex-col gap-thirdDegree  w-full">
                            {/* Vat Rates */}
                            <div className={country !== "LU" && 'hidden'}>
                                <Label fontWeight="bold" message="consultants.expense.VATBreakdown" />
                                <h3 className="font-bold mb-2"></h3>
                                <div className="flex flex-row gap-secondDegree justify-center items-center grid grid-cols-4">
                                    <div className="flex flex-col gap-2 grow col-span-3">
                                        {(isForApprovalUseCase) ? (
                                            Object.entries(vatValues).map(([uuid, vatData], index) => (
                                                <div key={index} className='grid grid-cols-3  gap-firstDegree items-center text-right '>
                                                    <div className="col-span-1 pr-2">
                                                        <Label fontWeight="semibold" >{vatData.vatRate} %</Label>
                                                    </div>
<Input
                                                            type="number"
                                                            min="0"
                                                            step="0.01"
                                                            onWheel={(e) => e.target.blur()}
                                                            name={`item${index}`}
                                                            value={vatData.vatAmount || undefined}
                                                            disabled={isForApprovalUseCase}
                                                            onChange={(e) => { handleVatChange(e, { uuid, rate: vatData.vatRate }) }}
                                                            placeholder={t("consultants.expense.totalVatRate")}
                                                            className="p-2 border border-greyStrong rounded-md text-body w-fit text-right"
                                                        />
                                                    {/* <div className="col-span-2 pr-2 flex items-center gap-firstDegree">
                                                        
                                                        {/* <Label fontWeight="semibold" className="w-fit"> {vatData.amount}</Label> */}
                                                    {/*</div> */}
                                                </div>
                                            ))
                                        ) : (
                                            errorVat ? <PrintErrorMessage error={errorVat} className={'col-span-3'} /> :
                                                loadingVat ? <span className='loader' /> : (
                                                    vatRates.map((item, index) => (
                                                        item.status === "ACTIVE" && <div key={index} className='grid grid-cols-3  gap-firstDegree items-center text-right '>
                                                            <div className="col-span-1 pr-2">
                                                                <Label fontWeight="semibold" >{item.rate} %</Label>
                                                            </div>
                                                            {/* <div className="col-span-2 pr-2 flex items-center gap-firstDegree overflow-visible nowrap"> */}
                                                                <Input
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                                name={`item${index}`}
                                                                value={vatValues[item?.uuid]?.vatAmount || undefined}
                                                                onChange={(e) => { handleVatChange(e, item) }}
                                                                placeholder={t("consultants.expense.totalVatRate")}
                                                                className={`p-2 border border-greyStrong rounded-md text-body w-[80%] col-span-2 text-right ${totalVatError === true && `border-error`} ${totalVatError === false && `border-success`} ${totalVatError === null && `border-greyStrong`}  `}
                                                            />
                                                            {/* {vatValues[item?.uuid]?.amount && <Label fontWeight="semibold" className="">= {vatValues[item?.uuid]?.amount}</Label>}</div> */}
                                                        </div>
                                                    ))
                                                )
                                        )}
                                        {Object.keys(vatNotAvailable).length > 0 && (
                                                                        <>
                                                                            <Label>Les taux suivants ne sont plus disponibles, veuillez modifier votre saisie : </Label>
                                                                            {Object.entries(vatNotAvailable).map(([vatRate, vatAmount], index) => (
                                                                                <div key={index} className='gap-firstDegree items-center text-left'>
                                                                                    <Label fontWeight="semibold">{vatRate} % : </Label>
                                                                                    <Label className="col-span-2">{vatAmount} {currency}</Label>
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                    )}
                                    </div>
                                    <div className='grow flex flex-col gap-firstDegree items-center text-right col-span-1'>
                                        <span><Label fontWeight="semibold" message="consultants.expense.totalVAT" /> : </span>
                                        <Label>{totalVat?.toFixed(2)} {currency}</Label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
                {country == "LU" && <Separator />}

                <div className={` flex gap-secondDegree items-center ${(country !== "LU") && 'hidden'}`}>
                    <div className='grow'>
                        <Label size="h3" message="consultants.expense.amountInclTaxs" />
                    </div>
                    <Label fontWeight="bold" size="h3" color="primary" >{totalAmount} {currency}</Label>



                </div>
                <Input type='submit' id="submitFormNewExpense" className='hidden' ref={submitRef} />

            </form>
        </div>
    );
}
